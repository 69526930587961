import sal from 'sal.js'
import Rellax from 'rellax'
import Flickity from 'flickity-fade'
// import 'flickity-imagesloaded'
import RModal from 'rmodal'
import { Fancybox } from "@fancyapps/ui";

document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('video.home-bg')) {
        let video = document.querySelector('video.home-bg');
        let observerToPlayVideo = new IntersectionObserver((entries, observer) => { 
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0.5) {
                    video.play();
                }
            });
        }, {
            threshold: 0.5
        });
        observerToPlayVideo.observe(video);

        let observerToResetVideo = new IntersectionObserver((entries, observer) => { 
            entries.forEach(entry => {
                video.currentTime = 0;
            });
        }, {
            threshold: 0
        });
        observerToResetVideo.observe(video);
    }







    let teamMemberIndexToSlideTo = 0;
    let coreTeamSliderflkty = false;
    let advisoryTeamSliderflkty = false;
    let transformationTeamSliderflkty = false;
    sal({
        threshold: 0.3
    });

    // if (document.querySelector('.whats-in-it-for-me ul li')) {
    //     const element = document.querySelector('.whats-in-it-for-me ul li');
    //     element.addEventListener('sal:in', ({ detail }) => {

    //     });
    // }
    
    if (document.querySelector('.rellax')) {
        var rellax = new Rellax('.rellax');
    }

    var body  = document.querySelector('body');
    var menuToggler = document.querySelector('.menu-toggler');
    var menu = document.querySelector('.overlay-menu');
    var menuActive = false;
    var header = document.querySelector('header.site-header');
    var main = document.querySelector('main');

    function openMenu() {
        let headerHeight = header.offsetHeight;
        main.style.marginTop = headerHeight + 'px';
        body.classList.add('menu-is-active');
        menu.classList.add('is-active');
        menuToggler.firstChild.nodeValue = "Back";
        menuActive = true;
    }

    function closeMenu() {
        main.style.marginTop = '0px';
        body.classList.remove('menu-is-active');
        menu.classList.remove('is-active');
        menuToggler.firstChild.nodeValue = "Menu";
        menuActive = false;
    }

    menuToggler.addEventListener('click', function (event) {
        if (menuActive) {
            closeMenu();
        } else {
            openMenu();
        }
    });


    setTimeout(() => {
        if (document.querySelector('.page-id-12 .your-journey-to-better .tetris-1')) {
            document.querySelector('.page-id-12 .your-journey-to-better .tetris-1').classList.add('visible');
        }

        if (document.querySelector('.page-id-12 .your-journey-to-better .tetris-2')) {
            document.querySelector('.page-id-12 .your-journey-to-better .tetris-2').classList.add('visible');
        }

        if (document.querySelector('.page-id-10 .experiences .tetris-1')) {
            document.querySelector('.page-id-10 .experiences .tetris-1').classList.add('visible');
        }

        if (document.querySelector('.page-id-10 .experiences .tetris-2')) {
            document.querySelector('.page-id-10 .experiences .tetris-2').classList.add('visible');
        }

        if (document.querySelector('.page-id-27 .the-team .tetris-1')) {
            document.querySelector('.page-id-27 .the-team .tetris-1').classList.add('visible');
        }

        if (document.querySelector('.page-id-27 .the-team .tetris-2')) {
            document.querySelector('.page-id-27 .the-team .tetris-2').classList.add('visible');
        }

        if (document.querySelector('.page-id-21 .testimonials .tetris-1')) {
            document.querySelector('.page-id-21 .testimonials .tetris-1').classList.add('visible');
        }

        if (document.querySelector('.page-id-21 .testimonials .tetris-2')) {
            document.querySelector('.page-id-21 .testimonials .tetris-2').classList.add('visible');
        }

        if (document.querySelector('.page-id-24 .we-believe .tetris-1')) {
            document.querySelector('.page-id-24 .we-believe .tetris-1').classList.add('visible');
        }

        if (document.querySelector('.page-id-24 .we-believe .tetris-2')) {
            document.querySelector('.page-id-24 .we-believe .tetris-2').classList.add('visible');
        }
    }, 1000);

    if (document.querySelector('.testimonials-slider ul')) {
        var testimonialSlider = document.querySelector('.testimonials-slider ul');
        var testimonialSliderflkty = new Flickity( testimonialSlider, {
            pageDots: false,
            fade: true,
            wrapAround: true,
            arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });
    }

    if (document.querySelector('.whats-in-it-for-me ul')) {
        var whatsInItForMeSlider = document.querySelector('.whats-in-it-for-me ul');
        var whatsInItForMeSliderflkty = new Flickity( whatsInItForMeSlider, {
            pageDots: false,
            watchCSS: true,
            wrapAround: true,
            arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });
    }





    // Creates Core Team Modal
    if (document.getElementById('modal-core-team')) {
        var modalCoreTeam = new RModal(
            document.getElementById('modal-core-team')
            , {
                beforeOpen: function(next) {
                    next();
                },
                afterOpen: function() {
                    coreTeamSliderflkty.resize();
                    coreTeamSliderflkty.select(teamMemberIndexToSlideTo, false, true);
                },
                beforeClose: function(next) {
                    next();
                },
                dialogOpenClass: 'animate__slideInDown',
                dialogCloseClass: 'animate__slideOutUp',
                bodyClass: 'modal-open',
                focus: false,
                escapeClose: true,
                adaptiveHeight: true
            }
        );
    }

    // Creates Advisory Team Modal
    if (document.getElementById('modal-advisory-team')) {
        var modalAdvisoryTeam = new RModal(
            document.getElementById('modal-advisory-team')
            , {
                beforeOpen: function(next) {
                    next();
                },
                afterOpen: function() {
                    advisoryTeamSliderflkty.resize();
                    advisoryTeamSliderflkty.select(teamMemberIndexToSlideTo, false, true);
                },
                beforeClose: function(next) {
                    next();
                },
                dialogOpenClass: 'animate__slideInDown',
                dialogCloseClass: 'animate__slideOutUp',
                bodyClass: 'modal-open',
                focus: false,
                escapeClose: true,
                adaptiveHeight: true
            }
        );
    }

    // Creates Transformation Team Modal
    if (document.getElementById('modal-transformation-team')) {
        var modalTransformationTeam = new RModal(
            document.getElementById('modal-transformation-team')
            , {
                beforeOpen: function(next) {
                    next();
                },
                afterOpen: function() {
                    transformationTeamSliderflkty.resize();
                    transformationTeamSliderflkty.select(teamMemberIndexToSlideTo, false, true);
                },
                beforeClose: function(next) {
                    next();
                },
                dialogOpenClass: 'animate__slideInDown',
                dialogCloseClass: 'animate__slideOutUp',
                bodyClass: 'modal-open',
                focus: false,
                escapeClose: true,
                adaptiveHeight: true
            }
        );
    }

    // Creates Flickity for Core Team
    if (document.querySelector('.page-id-27 #modal-core-team ul')) {
        var coreTeamSlider = document.querySelector('.page-id-27 #modal-core-team ul');
        coreTeamSliderflkty = new Flickity( coreTeamSlider, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });

        document.querySelector(".page-id-27 #modal-core-team nav button.prev").addEventListener("click", function() {
            coreTeamSliderflkty.previous(true, false);
        });

        document.querySelector(".page-id-27 #modal-core-team nav button.next").addEventListener("click", function() {
            coreTeamSliderflkty.next(true, false);
        });

        document.querySelector(".page-id-27 #modal-core-team button.close").addEventListener("click", function() {
            modalCoreTeam.close();
        });
    }

    // Creates Flickity for Advisory Team
    if (document.querySelector('.page-id-27 #modal-advisory-team ul')) {
        var advisoryTeamSlider = document.querySelector('.page-id-27 #modal-advisory-team ul');
        advisoryTeamSliderflkty = new Flickity( advisoryTeamSlider, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            arrowShape: "m99.2041078 48.0926964-32.4302412-32.4302412c-1.0539828-1.0539828-2.7673806-1.0539828-3.8213634 0-1.0539828 1.0539829-1.0539828 2.7673806 0 3.8213635l27.8143369 27.8143368h-88.06432c-1.4917911 0-2.7025201 1.210729-2.7025201 2.7025201s1.210729 2.7025201 2.7025201 2.7025201h88.06432l-27.8143369 27.8143369c-1.0539828 1.0539828-1.0539828 2.7673806 0 3.8213634.5296939.529694 1.2215391.7891359 1.9133842.7891359.6918452 0 1.3836903-.264847 1.9133843-.7891359l32.4302412-32.4302412c1.0539831-1.0539828 1.0539831-2.7673806 0-3.8213634z"
        });

        document.querySelector(".page-id-27 #modal-advisory-team nav button.prev").addEventListener("click", function() {
            advisoryTeamSliderflkty.previous(true, false);
        });

        document.querySelector(".page-id-27 #modal-advisory-team nav button.next").addEventListener("click", function() {
            advisoryTeamSliderflkty.next(true, false);
        });

        document.querySelector(".page-id-27 #modal-advisory-team button.close").addEventListener("click", function() {
            modalAdvisoryTeam.close();
        });
    }

        // Creates Flickity for Transformation Team
    if (document.querySelector('.page-id-27 #modal-transformation-team ul')) {
        var transformationTeamSlider = document.querySelector('.page-id-27 #modal-transformation-team ul');
        transformationTeamSliderflkty = new Flickity( transformationTeamSlider, {
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            arrowShape: "m99.2041078 48.0926964-32.4302412-32.4302412c-1.0539828-1.0539828-2.7673806-1.0539828-3.8213634 0-1.0539828 1.0539829-1.0539828 2.7673806 0 3.8213635l27.8143369 27.8143368h-88.06432c-1.4917911 0-2.7025201 1.210729-2.7025201 2.7025201s1.210729 2.7025201 2.7025201 2.7025201h88.06432l-27.8143369 27.8143369c-1.0539828 1.0539828-1.0539828 2.7673806 0 3.8213634.5296939.529694 1.2215391.7891359 1.9133842.7891359.6918452 0 1.3836903-.264847 1.9133843-.7891359l32.4302412-32.4302412c1.0539831-1.0539828 1.0539831-2.7673806 0-3.8213634z"
        });

        document.querySelector(".page-id-27 #modal-transformation-team nav button.prev").addEventListener("click", function() {
            transformationTeamSliderflkty.previous(true, false);
        });

        document.querySelector(".page-id-27 #modal-transformation-team nav button.next").addEventListener("click", function() {
            transformationTeamSliderflkty.next(true, false);
        });

        document.querySelector(".page-id-27 #modal-transformation-team button.close").addEventListener("click", function() {
            modalTransformationTeam.close();
        });
    }







    // Sets member index to slide to when clicked on
    document.querySelectorAll(".the-core-team ul li.team-member--grid .team-member__read-more").forEach(teamMember => {
        teamMember.addEventListener("click", function(item) {
            teamMemberIndexToSlideTo = teamMember.parentNode.dataset.index
            modalCoreTeam.open();
        });
    });

    document.querySelectorAll(".the-advisory-team ul li.team-member--grid .team-member__read-more").forEach(teamMember => {
        teamMember.addEventListener("click", function(item) {
            teamMemberIndexToSlideTo = teamMember.parentNode.dataset.index
            modalAdvisoryTeam.open();
        });
    });

    document.querySelectorAll(".the-transformation-team ul li.team-member--grid .team-member__read-more").forEach(teamMember => {
        teamMember.addEventListener("click", function(item) {
            teamMemberIndexToSlideTo = teamMember.parentNode.dataset.index
            modalTransformationTeam.open();
        });
    });








    // Who we are working with Slider
    if (document.querySelector('.who-we-are-working-with ul')) {
        var whoWeAreWorkingWithSlider = document.querySelector('.who-we-are-working-with ul');
        var whoWeAreWorkingWithSliderFlickity = new Flickity(whoWeAreWorkingWithSlider, {
            pageDots: false,
            prevNextButtons: true,
            autoPlay: true,
            wrapAround: true,
            // arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });
    }




    // Who we are working with Slider
    if (document.querySelector('.ctf-tweets')) {
        var tweetSlider = document.querySelector('.ctf-tweets');
        var tweetSliderFlickity = new Flickity(tweetSlider, {
            pageDots: false,
            prevNextButtons: false,
            fade: true,
            autoPlay: 8000,
            wrapAround: true,
            arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });
    }


    function dealWithHeader() {
        let header2 = false;
        if (header2 = document.querySelector('header.site-header')) {
            let header_height = header2.offsetHeight;
            document.documentElement.style.setProperty('--header-height', header_height + 'px');
        }
        
        if (document.querySelector('body').classList.contains('home')) {
            let page_heading = false;
            if (page_heading = document.querySelector('.in-pursuit-of-better')) {
                let page_heading_height = page_heading.offsetHeight;    
                document.documentElement.style.setProperty('--page-heading-height', page_heading_height + 'px');
            }
        } else {
            let page_heading = false;
            if (page_heading = document.querySelector('.page-heading')) {
                let page_heading_height = page_heading.offsetHeight;    
                document.documentElement.style.setProperty('--page-heading-height', page_heading_height + 'px');
            }
        }
    }

    dealWithHeader();

    window.addEventListener('resize', function() {
        dealWithHeader();
    });



    function openModal(modalClass) {
        const modal = document.querySelector(modalClass);
        modal.classList.add('open');
        modal.querySelector('.close').addEventListener('click', function() {
            modal.classList.remove('open');
        });
    }

    document.querySelectorAll('button[data-modal^="modal2"]').forEach(button => {
        button.addEventListener('click', function(e) {
            console.log('clicked');
            let modalClass = '.' + e.target.dataset.modal;
            openModal(modalClass);
        });
    });





    // Loughborough slider
    if (document.querySelector('.loughborough-slider')) {
        var lbSlider = document.querySelector('.loughborough-slider ul');
        var lbSliderFlickity = new Flickity(lbSlider, {
            pageDots: false,
            prevNextButtons: true,
            autoPlay: 8000,
            wrapAround: true,
            // imagesLoaded: true,
            arrowShape: "M.795892 37.907304l32.430241 32.43024c1.053983 1.053984 2.767381 1.053984 3.821364 0 1.053983-1.053982 1.053983-2.76738 0-3.821363L9.23316 38.701844h88.06432c1.491791 0 2.70252-1.210729 2.70252-2.70252 0-1.49179-1.210729-2.70252-2.70252-2.70252H9.23316L37.047497 5.482467c1.053983-1.053982 1.053983-2.76738 0-3.821363-.529694-.529694-1.22154-.789136-1.913384-.789136-.691846 0-1.38369.264847-1.913385.789136L.790488 34.091345c-1.053984 1.053983-1.053984 2.76738 0 3.821364l.005404-.005405z"
        });
    }





    if (document.querySelector('ul.gallery-slider')) {
        var gallerySlider = document.querySelector('ul.gallery-slider');
        var gallerySliderflkty = new Flickity(gallerySlider, {
            autoPlay: 3500,
            pageDots: false,
            fade: false,
            wrapAround: true,
            // imagesLoaded: true,
            prevNextButtons: false,
            pauseAutoPlayOnHover: false
        });
    }


    if (document.querySelector('.transformation-group')) {
        document.querySelectorAll('.transformation-group').forEach((group) => {
            group.addEventListener('click', (e) => {

                if (group.hasAttribute('open')) {
                    group.removeAttribute('open');
                } else {
                    // Loop through ALL groups and toggle open state
                    document.querySelectorAll('.transformation-group').forEach((group) => {
                        if (group.hasAttribute('open')) {
                            group.toggleAttribute('open');
                        }
                    });

                    group.setAttribute('open', 'open');
                }
            });
        });
    }


    if(document.querySelector('.fuel-kit-main')) {
        var documents = document.getElementsByClassName('documents')[0];
        var refuelTab = document.getElementById('refuel-tools-tab');
        var articleTab = document.getElementById('articles-videos-tab');

        function removeUrlParameter(parameter) { // function to remove doc-type parameter
            if (history.replaceState) {
                const url = window.location.href;
                const urlWithoutParam = url.replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1');
    
                history.replaceState({}, document.title, urlWithoutParam);
            }
        }
        
        refuelTab.addEventListener("click", (e) => {
            if(documents.classList.contains('article-active')) {
                documents.classList.add("refuel-active");
                documents.classList.remove("article-active");

                removeUrlParameter('doc-type');
                
            } else {
                return null;
            }
        });

        articleTab.addEventListener("click", (e) => {
            if(documents.classList.contains('refuel-active')) {
                documents.classList.add("article-active");
                documents.classList.remove("refuel-active");

                removeUrlParameter('doc-type');

            } else {
                return null;
            }
        });
    }

    Fancybox.bind("[data-fancybox]", {
        // Your custom options
      });      

});
